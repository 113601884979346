import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { DateTime } from "luxon"

//import rehypeReact from "rehype-react"

import Layout from "components/Layout"
import Head from "components/Head"
import ShareBtns from "components/ShareBtns"
import CardRelation from "components/CardRelation"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

//const renderAst = new rehypeReact({
//  createElement: React.createElement,
//  components: {
//    img: ZoomImage
//  }
//}).Compiler

export default ({ data }) => (
  <Layout>
    <Head
      id="column"
      pageTitle={data.airtable.data.title}
      pageDescription={data.airtable.data.content.childMarkdownRemark.excerpt}
      pageUrl={pjt.site.url + "/columns/" + data.airtable.data.slug + "/"}
      pageOgImage={
        data.airtable.data.images ? data.airtable.data.images[0].url : null
      }
    />
    <section className="section is-item is-space-half">
      <article className="card is-separate">
        <header className="card-header">
          <div className="inner is-space-half">
            <h1 className="text is-dark-2 is-lg">{data.airtable.data.title}</h1>
            <p className="text is-font-en-modern is-dark-3 is-space-right-half is-sm">
              <span className="text is-inline-block">
                <FontAwesomeIcon
                  className="icon"
                  icon={["far", "calendar-alt"]}
                />
                <span className="text">公開日: </span>
                <span className="text">
                  {DateTime.fromISO(data.airtable.data.publishedAt, {
                    zone: "Asia/Tokyo"
                  }).toFormat("yyyy/MM/dd")}
                </span>
              </span>
            </p>
          </div>
        </header>
        <section className="card-content">
          <div className="inner is-padding-lg is-tablet-padding-xxl is-space-2x">
            <div
              className="wysiwyg is-max-width-560px is-centering is-external-link-area is-disabled-link-gyazo is-space is-sm"
              dangerouslySetInnerHTML={{
                __html: data.airtable.data.content.childMarkdownRemark.html
              }}
            />
            <div className="box is-max-width-560px is-centering is-space">
              <h2 className="heading is-strong">
                <span className="icon">
                  <FontAwesomeIcon icon={["fas", "share-alt"]} />
                </span>
                <span className="text">シェア</span>
              </h2>
              <ShareBtns
                pageTitle={data.airtable.data.title}
                pageUrl={
                  pjt.site.url + "/items/" + data.airtable.data.slug + "/"
                }
              />
            </div>
            {data.airtable.data.items ? (
              <div className="box is-space">
                <h2 className="heading is-strong">
                  <span className="icon is-lg">
                    <FontAwesomeIcon icon={["fas", "shopping-basket"]} />
                  </span>
                  <span className="text">関連商品</span>
                </h2>
                <ul className="grid is-gap-2x">
                  {data.airtable.data.items.map((edge, i) =>
                    edge.data.published === true ? (
                      <li className="col is-mobile-12 is-tablet-6" key={i}>
                        <CardRelation
                          to={`/items/${edge.data.slug}/`}
                          src={edge.data.images[0].thumbnails.large.url}
                          title={edge.data.title}
                        />
                      </li>
                    ) : null
                  )}
                </ul>
              </div>
            ) : null}
          </div>
        </section>
      </article>
    </section>
  </Layout>
)

export const query = graphql`
  query GetColumn($slug: String!) {
    airtable(table: { eq: "columns" }, data: { slug: { eq: $slug } }) {
      data {
        title
        slug
        publishedAt
        images {
          url
        }
        content {
          childMarkdownRemark {
            html
            excerpt(truncate: true)
          }
        }
        items {
          data {
            title
            published
            slug
            images {
              url
              thumbnails {
                large {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
