import React from "react"
import { Link } from "gatsby"

class CardRelation extends React.Component {
  render() {
    return (
      <Link className="card is-border is-angle-right" to={this.props.to}>
        <div className="inner is-padding-lg">
          <div className="grid is-middle">
            <div className="col">
              <div className="card-thumb-wrap is-width-80px">
                <img
                  data-src={this.props.src}
                  className="img is-radius is-centering is-fade lazyload"
                  alt={this.props.title}
                />
              </div>
            </div>
            <div className="col is-mobile-0">
              <p className="text is-dark-2 is-sm">{this.props.title}</p>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default CardRelation
