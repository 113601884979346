import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

class ShareBtns extends React.Component {
  render() {
    const shareTitle = this.props.pageTitle + " - " + pjt.site.title
    return (
      <div className="btns is-bar">
        <a
          href={
            "https://twitter.com/share?text=" +
            encodeURIComponent(shareTitle) +
            "&url=" +
            this.props.pageUrl +
            "&via=" +
            pjt.site.twitter.id
          }
          target="blank"
          rel="nofollow"
          className="btn is-outline is-mobile-0"
        >
          <span className="icon is-scale-lg">
            <FontAwesomeIcon icon={["fab", "twitter"]} />
          </span>
        </a>
        <a
          href={
            "https://www.facebook.com/sharer.php?src=bm&u=" + this.props.pageUrl
          }
          target="blank"
          rel="nofollow"
          className="btn is-outline is-mobile-0"
        >
          <span className="icon is-scale-lg">
            <FontAwesomeIcon icon={["fab", "facebook-square"]} />
          </span>
        </a>
        <a
          href={
            "https://line.me/R/msg/text/?" +
            encodeURIComponent(this.props.pageTitle) +
            "%0D%0A" +
            this.props.pageUrl
          }
          target="blank"
          rel="nofollow"
          className="btn is-outline is-mobile-0"
        >
          <span className="icon is-scale-lg">
            <FontAwesomeIcon icon={["fab", "line"]} />
          </span>
        </a>
        <a
          href={
            "https://getpocket.com/edit?url=" +
            this.props.pageUrl +
            "&title=" +
            encodeURIComponent(this.props.pageTitle)
          }
          target="blank"
          rel="nofollow"
          className="btn is-outline is-mobile-0"
        >
          <span className="icon is-scale-lg">
            <FontAwesomeIcon icon={["fab", "get-pocket"]} />
          </span>
        </a>
      </div>
    )
  }
}

export default ShareBtns
